<mat-toolbar color="primary" class="main-menu">
  <span>Rencontres Contemporaines</span>
  <span class="spacer"></span>
  <div id="menu">
    <button mat-button (click)="header.scrollIntoView({behavior: 'smooth'})">Accueil</button>
    <button mat-button (click)="intro.scrollIntoView({behavior: 'smooth'})">A propos</button>
    <button mat-button (click)="concerts.scrollIntoView({behavior: 'smooth'})">Concerts</button>
    <button mat-button (click)="ateliers.scrollIntoView({behavior: 'smooth'})">Ateliers d'écoute</button>
    <button mat-button (click)="enpratique.scrollIntoView({behavior: 'smooth'})">En pratique</button>
    <!--<button mat-button>Fils rouges</button>-->
    <button mat-button (click)="partenaires.scrollIntoView({behavior: 'smooth'})">Partenaires et soutiens</button>
  </div>
</mat-toolbar>
<mat-sidenav-container id="#responsive-menu">
  <mat-sidenav #sidenav mode="side" opened="false">
    <div>
      <a (click)="sidenav.toggle()" href="#" mat-button>Close</a>
      <button mat-button (click)="header.scrollIntoView({behavior: 'smooth'})">Accueil</button>
      <button mat-button (click)="intro.scrollIntoView({behavior: 'smooth'})">A propos</button>
      <button mat-button (click)="concerts.scrollIntoView({behavior: 'smooth'})">Concerts</button>
      <button mat-button (click)="ateliers.scrollIntoView({behavior: 'smooth'})">Ateliers d'écoute</button>
      <button mat-button (click)="enpratique.scrollIntoView({behavior: 'smooth'})">En pratique</button>
      <button mat-button (click)="partenaires.scrollIntoView({behavior: 'smooth'})">Partenaires et soutiens</button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content fxFlexFill> Rencontres Contemporaines</mat-sidenav-content>
</mat-sidenav-container>


<header #header>
  <div>
    <img class="logo" (click)="showLoginDialog()" src="/assets/images/logo.jpg" alt="logo rc"/>
  </div>

  <div>
    <p class="title">
      Parcours-découverte de la musique XX°
      et XXI°, créations, reprises, dialogue entre classiques et oeuvres nouvelles.
    </p>
  </div>
  <div class="container info">
    <div class="row">
      <div class="col-md-1 info-icon-wrapper">
        <mat-icon class="info-icon">info</mat-icon>
      </div>

      <div class="col-md-11">
        <span class="font-italic">2025, la 31ème saison des Rencontres Contemporaines</span>
        <p>

          Rencontres avec des créateurs et interprètes d’aujourd’hui :
          Concerts, performances, résidences, séances d’écoute
        </p>
        <p>
          Les Rencontres contemporaines poursuivent leur route, avec toujours cette même curiosité pour les musiques en train de s’inventer,
          pour les formations improbables,  soutiennent les interprètes désireux de défendre les œuvres des XXème et XXIème siècle, insistent
          sur le temps de maturation du concert - temps de résidence, choix du répertoire, place donnée à  l’improvisation – et sur la qualité
          du partage avec le public : présentation des concerts, échanges avec les artistes, séances d’écoute permettent d’entrer de plain-pied
          dans la création !
        </p>
        <span
          class="font-italic">Laissez-vous surprendre !  Suivez-nous au fil de cette 31ème saison !</span>

      </div>
    </div>
  </div>
</header>
<section #intro class="intro">

  <div class="section-title">Les Rencontres Contemporaines ?</div>

  <div class="about">
    Portées par l’association Lien-Création, Les Rencontres Contemporaines vous invitent à découvrir et partager la
    musique de notre époque. Pour ce faire, elles programment des concerts, ateliers et rencontres. Elles suscitent de
    nouvelles œuvres, passant régulièrement commande aux compositeurs.<br><br>
    La petite équipe des Rencontres Contemporaines travaille particulièrement la façon d’accueillir les artistes et
    le public, soucieuse d’une qualité d’échange et de rencontre. Elle est aussi fidèle à des artistes dont elle
    suit le travail et les créations dans la durée, ainsi qu’à des lieux.<br><br>

    Les Rencontres Contemporaines programment en Haute-Loire et à Lyon.<br><br>

    A Lyon, un lieu de résidence est mis à disposition au siège lyonnais des Rencontres Contemporaines, ouvert à tous
    les artistes qui souhaitent l’utiliser pour ou en-dehors des programmes Rencontres Contemporaines.
  </div>
</section>
<section #concerts class="concerts">

  <app-concerts [logged]="logged"></app-concerts>

</section>
<section #ateliers class="ateliers">

  <div class="section-title">Ateliers d'écoute
  </div>
  <span>par Philippe Gouttenoire, compositeur, « dégustation musicale » tout public (=savant ou ignorant !)</span>
  <br><br><br><br>
  <div class="atelier-layout">
    <div class="atelier-description">
      <h3>Des ateliers d'écoute ?</h3>
      <p>
        Toujours menés par des compositeurs, les ateliers proposent une écoute en profondeur. Ils furent inaugurés par
        Robert Pascal en 2008, Christine Mennesson prit le relais en 2014, puis Philippe Gouttenoire proposa cette
        ouverture pour tout le répertoire, pas seulement contemporain, et cela dure depuis 2015, 33 ateliers,
        fidèlement suivis…
        L’invité du soir n’est dévoilé qu’à la première écoute de chaque atelier.
      </p>
      <p>
        Ces invités-surprise furent au fil des ans :
        Monteverdi, Debussy, Bartok, Chopin, Mozart, Ravel, Bach, Dutilleux, Mahler, Schubert, Berg, Debussy, Beethoven,
        Stravinski, Gesualdo, Scriabine, Brahms, Webern, Bach et la notion du temps, les Canons, Antoine de Bertrand,
        Crumb, Schumann, Moussorgski, Ligeti, Debussy/Bachelard, Schubert, Benjamin, Mel Bonis, Mozart, Mahler, Berio,
        Bartok, la Folia à travers les âges, Berg, Palestrina/Monteverdi.
      </p>
      <p>

        Venez et parlez-en autour de vous. Rendez-vous au siège des Rencontres Contemporaines,
        4 place Louis Chazette, 69001 Lyon. Les inscriptions se prennent par sms ou message au
        <a href="tel:+330670429832">06 70 42 98 32</a> ou au
        <a href="+330622622060">06 22 62 20 60</a>.
        Vous pouvez aussi venir directement, il y aura toujours une chaise pour vous !

        NB – nous avons un matériel d’écoute digne de vos oreilles.
        La participation est fixée à 13 €, les étudiants – très bienvenus – sont nos invités.
      </p>
    </div>
    <div class="atelier-list">

      <app-ateliers [logged]="logged"></app-ateliers>

    </div>
  </div>
</section>
<section #enpratique class="enpratique">
  <div class="section-title">En pratique
  </div>
  <h2>Tarifs : </h2>
  <div class="tarif-layout">

    <mat-card>
      <mat-card-header>
        <mat-card-title class="inline-icon"><span>Plein Tarif &nbsp;</span>
          <mat-icon>credit_card</mat-icon>
        </mat-card-title>
        <mat-card-subtitle>
          &nbsp;
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <span class="tarif-text">15€</span>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title class="inline-icon">
          <span>Tarif réduit &nbsp;</span>
          <mat-icon>toll</mat-icon>
        </mat-card-title>
        <mat-card-subtitle>
          Adulte avec enfant, étudiant, chomeur
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <span class="tarif-text">9€</span>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title class="inline-icon">
          <span>Gratuit &nbsp;</span>
          <mat-icon>redeem</mat-icon>
        </mat-card-title>
        <mat-card-subtitle>
          Moins de 18 ans & PassCulture Jeune
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <span class="tarif-text">0€</span>
      </mat-card-content>
    </mat-card>


  </div>

  <h2>Tarifs spécifiques à certain lieux : </h2>
  <div class="tarif-layout">

    <mat-card>
      <mat-card-header>
        <mat-card-title><span>à l’hôpital</span></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <span class="tarif-text-small">Libre Participation</span>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title class="inline-icon">
          <span>La Voir Au Public</span>
        </mat-card-title>
        <mat-card-subtitle>

        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <span class="tarif-text-small">3€, 6€ ou 12€</span>
        <span class="tarif-text-x-small"> + 2 € d’adhésion Le La Voir Au Public</span>
      </mat-card-content>
      <mat-card-actions>
        <a mat-raised-button color="primary" href="tel:+330782132173">Réservation : 07 82 13 21 73</a>
      </mat-card-actions>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title class="inline-icon">
          <span>Atelier &nbsp;</span>
        </mat-card-title>
        <mat-card-subtitle>
          Gratuit pour les étudiants
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content fxLayout="column" fxLayoutAlign="center center">
        <span class="tarif-text">15€</span>
      </mat-card-content>
    </mat-card>


  </div>


  <h2>Contact :</h2>

  <div class="contact-layout">

    <mat-card class="article email">
      <mat-card-header>
        <mat-card-title class="inline-icon"><span>Mail &nbsp;</span>
          <mat-icon>email</mat-icon>
        </mat-card-title>
        <mat-card-subtitle>
          &nbsp;
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
          <span>
            contact@rencontres-contemporaines.com
          </span>
      </mat-card-content>
      <mat-card-actions>
        <a mat-raised-button color="primary" href="mailto:contact@rencontres-contemporaines.com">ECRIRE</a>
      </mat-card-actions>
    </mat-card>

    <mat-card class="article phone">
      <mat-card-header>
        <mat-card-title class="inline-icon"><span>Téléphone  &nbsp;</span>
          <mat-icon>phone</mat-icon>
        </mat-card-title>
        <mat-card-subtitle>
          &nbsp;
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <span>04 78 64 82 60</span><br>
        <span>06 22 62 20 60</span><br>
        <span>Réservations ateliers : 06 70 42 98 32</span><br>
      </mat-card-content>
      <mat-card-actions class="d-flex">
        <a mat-raised-button color="primary" href="tel:+330478648260">
          <mat-icon>phone</mat-icon>
          04 78 64 82 60</a>
        <a mat-raised-button color="primary" href="tel:+330622622060">
          <mat-icon>phone</mat-icon>
          06 22 62 20 60</a>
        <a mat-raised-button color="primary" href="tel:+330670429832">
          <mat-icon>phone</mat-icon>
          06 70 42 98 32</a>
      </mat-card-actions>
    </mat-card>
  </div>


</section>
<section #partenaires class="partenaires">
  <div class="section-title">Partenaires</div>
  <div class="partenaires-layout">
    <img src="/assets/images/spedidam.png"/>
    <img src="/assets/images/saint-jean-dieu.png" class="big"/>
    <img src="/assets/images/lavoir.png"/>
    <img src="/assets/images/conseil-general.png"/>
    <img src="/assets/images/drac.png" class="big"/>
    <img src="/assets/images/mazet.png"/>
    <img src="/assets/images/Logo monastier_signature.jpg"/>
    <img src="/assets/images/salabert.png"/>
  </div>
</section>
